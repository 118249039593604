import React, { useEffect, useRef } from 'react';

import { colors } from '../../styles/variables';

import { SectionAppearanceProvider } from '../../components/sections/SectionContext';

import Seo from '../../components/seo';
import {
    SectionHeroStart,
    SectionOrderedItems,
    SectionVideoContainer,
    SectionIconItems,
    SectionAccordion,
    SectionInlineTiles,
    SectionBigTiles,
} from '../../components/sections';

import {
    SMARTPLAN_HERO_CONFIG,
    SMARTPLAN_ORDERED_ITEMS_CONFIG,
    SMARTPLAN_VIDEO_CONTAINER_CONFIG,
    SMARTPLAN_ICON_ITEMS_CONFIG,
    SMARTPLAN_ACCORDION_SECTION,
    SMARTPLAN_INLINE_TILES_CONFIG,
    SMARTPLAN_BIG_TILES_CONFIG,
} from '../../content_config_smartplan';
import CookieModal from '../../components/organisms/CookieModal';

const IndexSmartPlan = () => {
    const pageBodyRef = useRef(null);

    useEffect(() => {
        const paypoDataPayload = {
            pageBodyHeight: pageBodyRef.current.clientHeight,
        };
    
        window.top.postMessage(JSON.stringify(paypoDataPayload), '*');
    }, []);
    
    return (
        <div className="page-body" ref={ pageBodyRef }>
            <SectionAppearanceProvider>
                <Seo title="Paypo - kup teraz, zapłać za 30 dni" />

                <SectionHeroStart
                    config={ SMARTPLAN_HERO_CONFIG }
                />

                <SectionOrderedItems
                    config={ SMARTPLAN_ORDERED_ITEMS_CONFIG }
                />

                <SectionVideoContainer
                    config={ SMARTPLAN_VIDEO_CONTAINER_CONFIG }
                    backgroundBottom={ colors.grayCultured }
                />

                <SectionIconItems
                    config={ SMARTPLAN_ICON_ITEMS_CONFIG }
                    background={ colors.grayCultured }
                    columns={ 3 }
                />

                <SectionAccordion
                    config={ SMARTPLAN_ACCORDION_SECTION }
                    isColorSectionAbove={ true }
                />

                <SectionInlineTiles
                    config={ SMARTPLAN_INLINE_TILES_CONFIG }
                />

                <SectionBigTiles
                    config={ SMARTPLAN_BIG_TILES_CONFIG }
                />

                <CookieModal />
            </SectionAppearanceProvider>
        </div>
    );
};

export default IndexSmartPlan;
